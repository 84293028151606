<template>
    <div>
        <div class='cellInternal'>{{ text }}</div>
    </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    props: ['record', 'column', 'withOptionNumbers'],
    computed: {
        text(){
            const { column, record, withOptionNumbers } = this;
            const { value, valueText } = record.responses[column] ?? {};
            let text = valueText ?? record[column] ?? '';

            if(withOptionNumbers && valueText && valueText !== value)
                text += ` (${value})`;

            return text;
        }
    }
});
</script>

<style lang="scss" scoped>
.cellInternal {
    margin: auto;
    width: 100%;
}
</style>