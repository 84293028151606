
import { ChatQuestion } from '@/ts/interfaces/Question';
import { defineComponent } from '@vue/runtime-core';
import { getAllRecords, pdfPath } from '../helpers';
import ResultsValue from './ResultsValue.vue';
import { isEqual } from 'lodash';

type highlighted = { row: number | null, column: number | null };
const recordsPerPage = 30;
export default defineComponent({
    components: { ResultsValue },
    watch: {
        '$route.params.qualifier': {
            immediate: true,
            async handler(){
                this.loading = true;
                const data = await getAllRecords();
                this.loading = false;
                data.questions = data.questions!.filter((q, index) => index === data.questions?.findIndex(q2 => q.compoundId === q2.compoundId));
                Object.assign(this, data);
                this.page = 1;
            }
        }
    },
    computed: {
        lastPage(){
            return Math.ceil(this.records.length / recordsPerPage);
        },
        columns(){
            return [
                ['id', 'מזהה'],
                ['age', 'גיל'],
                ['sex', 'מין'],
                ['agreedToShare', 'מוכן לחלוק'],
                ['hasCorona', 'חולה פעיל'],
                ...this.questions.map(question => [question.compoundId, question.text])
            ];
        },
        columnKeys(){ return this.columns.map(([column]) => column) },
        selectedColumnKeys(){ return Object.keys(this.selectedColumns) },
        columnCount(){
            return this.filter.columns ? this.selectedColumnKeys.length + 1 : this.columns.length;
        },
        showEmpty(){ return !this.filter.columns && !this.filter.empty },
        currentRecords(){
            return this.records.slice((this.page - 1) * recordsPerPage, this.page * recordsPerPage);
        }
    },
    data: () => ({
        loading: true,
        records: [],
        questions: [] as ChatQuestion[],
        page: 1,
        selectedColumns: {} as { [key: string]: boolean },
        filter: { columns: false, empty: false, optionNumbers: true },
        highlighted: { column: null, row: null } as highlighted,
        recordsPerPage
    }),
    methods: {
        nextPage(dir: 2 | 1 | -1 | -2){
            const doScroll = () => (this.$refs.dataTable as HTMLDivElement)?.scrollTo({ top: 0 });
            if(dir > 0 && this.page < this.lastPage){
                this.page = dir === 1 ? this.page + 1 : this.lastPage;
                doScroll();
            }
            if(dir < 0 && this.page > 1){
                this.page = dir === -1 ? this.page - 1 : 1;
                doScroll();
            }
        },
        pdfPath,
        synchronizeScroll(){
            (this.$refs.ids as HTMLDivElement).scrollTop = (this.$refs.grid as HTMLDivElement).scrollTop;
        },
        shouldShow(column: string){
            return column !== "id" && (!this.filter.columns || !this.selectedColumnKeys.length || this.selectedColumns[column]);
        },
        toggleColumn(column: string){
            this.selectedColumns[column] = !this.selectedColumns[column];
            this.filter.columns = this.filter.columns && (() => {
                for(let c in this.selectedColumns)
                    if(this.selectedColumns[c])
                        return true;
                return false;
            })();
        },
        toggleFilter(filter: 'columns' | 'empty' | 'optionNumbers'){
            if(filter === 'columns' && !this.selectedColumnKeys.length) return;
            this.filter[filter] = !this.filter[filter];
        },
        highlight(type: 'row' | 'column', highlightData: number, event: Event){
            if((event.target as HTMLDivElement).closest('.pdfLink')) return;
            
            this.highlighted[type] = !isEqual(highlightData, this.highlighted[type]) ? highlightData : null;
        }
    }
});
