<template>
    <div v-if='loading' id='dataLoading'>
        <loading-indicator />
    </div>
    <div v-else id='dataTable' ref='dataTable'>
        <div :style='{ gridTemplateColumns: `repeat(${columnCount}, 11rem)`, gridAutoRows: "7rem" }'>
            <div class='mainColumnHeader'>
               {{ columns[0][1] }}
               <div class='gridActions'>
                    <img
                        src='/images/filter-columns.png'
                        class='filterColumns'
                        :class='{ filterOn: filter.columns }'
                        @click='toggleFilter("columns")'
                    />
                    <img
                        src='/images/hash.png'
                        class='optionNumbers'
                        :class='{ filterOn: filter.optionNumbers }'
                        @click='toggleFilter("optionNumbers")'
                    />
                </div>
            </div>
            <template v-for='([key, column]) in columns' :key='key'>
                <div class='columnHeader' :class='{ selected: selectedColumns[key] }' v-if='shouldShow(key)' @click='toggleColumn(key)' :style='{ top: 0 }'> <!-- @click='highlight("column", index)' -->
                    <div>{{ column }}</div>
                </div>
            </template>
            <template v-for='(record, index) in currentRecords' :key='index'>
                <div class='rowHeader' @click='highlight("row", index, $event)'>
                    <a :href='pdfPath(record.uid)' v-if='record.agreedAt' class='pdfLink'>
                        <img src='/images/pdf.png' />
                    </a>
                    {{ record.uid }}
                </div>
                <template v-for='(column, columnIndex) of columnKeys' :key='column'>
                    <results-value
                        v-if='shouldShow(column)'
                        v-bind='{ record, column, withOptionNumbers: filter.optionNumbers }'
                        :class='{ tableCell: true, isHighlighted: highlighted.column === columnIndex || highlighted.row === index }'
                    />
                </template>
            </template>
        </div>
    </div>
    <teleport to='#navSlot' v-if='!loading && records.length > recordsPerPage'>
        <div id='tableNavButtons'>
            <template v-if='page !== 1'>
                <button class='material-icons-round' @click='nextPage(-2)'>last_page</button>
                <button class='material-icons-round' @click='nextPage(-1)'>chevron_right</button>
            </template>
            <template v-else><div /><div /></template>
            <div>{{ page }}</div>
            <template v-if='page !== lastPage'>
                <button class='material-icons-round' @click='nextPage(1)'>chevron_left</button>
                <button class='material-icons-round' @click='nextPage(2)'>first_page</button>
            </template>
            <template v-else><div /><div /></template>
        </div>
    </teleport>
</template>

<script lang="ts">
import { ChatQuestion } from '@/ts/interfaces/Question';
import { defineComponent } from '@vue/runtime-core';
import { getAllRecords, pdfPath } from '../helpers';
import ResultsValue from './ResultsValue.vue';
import { isEqual } from 'lodash';

type highlighted = { row: number | null, column: number | null };
const recordsPerPage = 30;
export default defineComponent({
    components: { ResultsValue },
    watch: {
        '$route.params.qualifier': {
            immediate: true,
            async handler(){
                this.loading = true;
                const data = await getAllRecords();
                this.loading = false;
                data.questions = data.questions!.filter((q, index) => index === data.questions?.findIndex(q2 => q.compoundId === q2.compoundId));
                Object.assign(this, data);
                this.page = 1;
            }
        }
    },
    computed: {
        lastPage(){
            return Math.ceil(this.records.length / recordsPerPage);
        },
        columns(){
            return [
                ['id', 'מזהה'],
                ['age', 'גיל'],
                ['sex', 'מין'],
                ['agreedToShare', 'מוכן לחלוק'],
                ['hasCorona', 'חולה פעיל'],
                ...this.questions.map(question => [question.compoundId, question.text])
            ];
        },
        columnKeys(){ return this.columns.map(([column]) => column) },
        selectedColumnKeys(){ return Object.keys(this.selectedColumns) },
        columnCount(){
            return this.filter.columns ? this.selectedColumnKeys.length + 1 : this.columns.length;
        },
        showEmpty(){ return !this.filter.columns && !this.filter.empty },
        currentRecords(){
            return this.records.slice((this.page - 1) * recordsPerPage, this.page * recordsPerPage);
        }
    },
    data: () => ({
        loading: true,
        records: [],
        questions: [] as ChatQuestion[],
        page: 1,
        selectedColumns: {} as { [key: string]: boolean },
        filter: { columns: false, empty: false, optionNumbers: true },
        highlighted: { column: null, row: null } as highlighted,
        recordsPerPage
    }),
    methods: {
        nextPage(dir: 2 | 1 | -1 | -2){
            const doScroll = () => (this.$refs.dataTable as HTMLDivElement)?.scrollTo({ top: 0 });
            if(dir > 0 && this.page < this.lastPage){
                this.page = dir === 1 ? this.page + 1 : this.lastPage;
                doScroll();
            }
            if(dir < 0 && this.page > 1){
                this.page = dir === -1 ? this.page - 1 : 1;
                doScroll();
            }
        },
        pdfPath,
        synchronizeScroll(){
            (this.$refs.ids as HTMLDivElement).scrollTop = (this.$refs.grid as HTMLDivElement).scrollTop;
        },
        shouldShow(column: string){
            return column !== "id" && (!this.filter.columns || !this.selectedColumnKeys.length || this.selectedColumns[column]);
        },
        toggleColumn(column: string){
            this.selectedColumns[column] = !this.selectedColumns[column];
            this.filter.columns = this.filter.columns && (() => {
                for(let c in this.selectedColumns)
                    if(this.selectedColumns[c])
                        return true;
                return false;
            })();
        },
        toggleFilter(filter: 'columns' | 'empty' | 'optionNumbers'){
            if(filter === 'columns' && !this.selectedColumnKeys.length) return;
            this.filter[filter] = !this.filter[filter];
        },
        highlight(type: 'row' | 'column', highlightData: number, event: Event){
            if((event.target as HTMLDivElement).closest('.pdfLink')) return;
            
            this.highlighted[type] = !isEqual(highlightData, this.highlighted[type]) ? highlightData : null;
        }
    }
});
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/_dataTable.scss";

#dataLoading {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#tableNavButtons {
    position: absolute;
    right: 18rem;
    top: 1.25rem;
    display: grid;
    grid-template-columns: 1.5rem 1.5rem 2rem 1.5rem 1.5rem;
    align-items: center;
    gap: .5rem;
    width: 10rem;

    & > div {
        text-align: center;
    }

    & button {
        color: #d35e5e;
        cursor: pointer;
        padding: 0;

        &:hover {
            color: #b44444;
        }
    }
}

#dataTable {
    max-width: 100vw;
    max-height: calc(100vh - 4rem);
    margin: 0;

    & > div {
        background-color: transparent !important;
    }
}

.columnHeader, .tableCell {
    align-items: flex-start;
    overflow-y: auto;

    & > div {
        margin: auto;
    }
}

.tableCell {
    background-color: rgba(white, .75) !important;
}

.columnHeader.selected {
    background-color: $swatchA !important;
}

.mainColumnHeader {
    position: relative;
}
.gridActions {
    @include flexbox(center-all, column);
    padding-top: 0.5rem;
    position: absolute;
    right: .2rem;

    & .filterColumns {
        filter: grayscale(60%);
        margin-bottom: 0.5rem;
    }
    & .optionNumbers {
        filter: grayscale(100%)
    }

    & .filterColumns, & .optionNumbers {
        width: 1.6rem;
        cursor: pointer;

        &.filterOn {
            filter: contrast(110%);
        }
        &:hover {
            transform: scale(1.1);
        }
    }
}

.rowHeader {
    position: relative;
}
.pdfLink {
    position: absolute;
    right: .5rem;
    transform: translateY(.2rem);

    & img {
        width: 1.8rem;

        &:hover {
            transform: scale(1.2);
            transition: transform .1s;
        }
    }
}
</style>