
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
    props: ['record', 'column', 'withOptionNumbers'],
    computed: {
        text(){
            const { column, record, withOptionNumbers } = this;
            const { value, valueText } = record.responses[column] ?? {};
            let text = valueText ?? record[column] ?? '';

            if(withOptionNumbers && valueText && valueText !== value)
                text += ` (${value})`;

            return text;
        }
    }
});
